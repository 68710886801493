<template>
  <div class="d-flex flex-column container">
    <v-btn-toggle
      v-model="radioGroup"
      mandatory="true"
      tile
      color="deep-purple accent-3"
      group
    >
      <v-btn value="1">Spielersuche</v-btn>
      <v-btn value="2">Benutzerdefiniert</v-btn>
    </v-btn-toggle>
    <div v-show="radioGroup === '1'">
      <search-player
        @selectPlayer="onSelectPlayer"
        @search="onPlayerSearch"
        class="flex-shrink-1"
      />
    </div>
    <v-text-field
      v-show="radioGroup === '2'"
      v-model="customMarketValue"
      label="Wert"
      prefix="€"
      type="number"
      class="flex-grow-0"
      clearable
    ></v-text-field>
    <v-dialog v-model="showDialog" max-width="100%">
      <v-card>
        <v-card-title class="text-h5"> Berechneter Wert </v-card-title>
        <v-card-text>
          {{ calculatedTransferValue | formatMarketValue }}
          €
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <v-fab-transition>
        <v-btn
          class="calc-btn"
          v-show="isReadyToCalculate"
          dark
          absolute
          bottom
          right
          fab
          @click.stop="onCalculateTransferValue"
        >
          <v-icon>mdi-calculator</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-text>
  </div>
</template>

<script>
import SearchPlayer from "./SearchPlayer.vue";
import { mapGetters } from "vuex";

export default {
  components: { SearchPlayer },
  data() {
    return {
      radioGroup: "1",
      calculatedTransferValue: null,
      selectedplayer: null,
      customMarketValue: "",
      isReadyToCalculate: false,
      calculation: {
        maxToleranceInPercent: 20,
      },
      showDialog: false,
    };
  },
  watch: {
    selectedplayer(newPlayer) {
      this.isReadyToCalculate = this.radioGroup === "1" && newPlayer;
    },
    customMarketValue(newValue) {
      this.isReadyToCalculate =
        this.radioGroup === "2" && newValue && newValue.length > 0;
    },
    radioGroup(newValue) {
      this.isReadyToCalculate =
        newValue === "1"
          ? !!this.selectedplayer
          : this.customMarketValue && this.customMarketValue.length > 0;
    },
  },
  computed: {
    ...mapGetters("user", ["getMarketValue"]),
  },
  methods: {
    _getMarketValue() {
      if (this.radioGroup === "1") {
        return this.selectedplayer.marketRawValue;
      }
      try {
        return parseInt(this.customMarketValue);
      } catch {
        return 0;
      }
    },
    onSelectPlayer(player) {
      this.selectedplayer = player;
    },
    onCalculateTransferValue() {
      const marketValue = this._getMarketValue();
      const toleranceInPercent = Math.floor(
        Math.random() * this.calculation.maxToleranceInPercent
      );
      const valueAdditive = (marketValue * toleranceInPercent) / 100;
      const sign = Math.floor(Math.random() * 2);
      this.calculatedTransferValue = (
        sign === 0 ? marketValue - valueAdditive : marketValue + valueAdditive
      ).toFixed();
      this.showDialog = !!this.calculatedTransferValue;
    },
    onPlayerSearch() {
      this.calculatedTransferValue = "";
    },
  },
  filters: {
    formatMarketValue(value) {
      return value ? Number(value).toLocaleString("de-DE") : 0;
    },
  },
};
</script>

<style scoped>
.container {
  height: inherit;
}
.calc-btn {
  right: 10px !important;
  bottom: 10px !important;
}
</style>

