<template>
  <div class="d-flex flex-column container pl-0 pr-0">
    <div class="d-flex align-center">
      <v-text-field
        v-model="searchQuery"
        label="Name of player"
        :outlined="true"
        :hide-details="true"
        @keydown.enter.prevent="onSearch"
        clearable
      ></v-text-field>
      <v-btn :disabled="!searchQuery" icon @click="onSearch">
        <v-icon>mdi-search-web</v-icon>
      </v-btn>
    </div>
    <v-card :loading="getPlayersSearchLoading" class="mt-1" flat width="100%">
      <v-list two-line class="list">
        <v-radio-group v-model="selectedPlayerItem">
          <template v-for="item in getPlayers">
            <v-list-item @click="itemType(item.name)" :key="item.name">
              <v-list-item-action class="mr-3">
                <v-radio :value="item.name" :key="item.name"></v-radio>
              </v-list-item-action>
              <v-img :src="item.imageUrl" class="player-image mr-2" />
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{ item.club }} -
                  {{ item.marketRawValue | formatMarketValue }}
                  €</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-radio-group>
      </v-list>
    </v-card>
    <span v-if="getPlayers.length === 0" class="searchNoDataText"
      >Keine Spieler gefunden!</span
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      searchQuery: "",
      selectedPlayerItem: "",
    };
  },
  computed: {
    ...mapGetters("user", ["getPlayers", "getPlayersSearchLoading"]),
  },
  methods: {
    ...mapActions("user", ["searchPlayer"]),
    onSearch() {
      if (!this.searchQuery) {
        return;
      }
      this.itemType("");
      this.$emit("search");
      this.searchPlayer({
        searchQuery: this.searchQuery,
      });
    },
    itemType(payload) {
      this.selectedPlayerItem = payload;
      const player = this.getPlayers.find((player) => player.name === payload);
      this.$emit("selectPlayer", player);
    },
  },
  filters: {
    formatMarketValue(value) {
      return value ? Number(value).toLocaleString("de-DE") : 0;
    },
  },
};
</script>

<style scoped>
.container {
  overflow-y: hidden;
  height: inherit;
}
.list {
  overflow-y: auto;
}
.v-input--selection-controls {
  margin-top: 0px;
}
.searchNoDataText {
  align-self: center;
}
.player-image {
  height: 36px;
  width: 28px;
  min-height: 36px;
  min-width: 28px;
  max-height: 36px;
  max-width: 28px;
}
</style>

