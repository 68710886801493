import gql from "graphql-tag";
import graphqlClient from "../../../utils/graphql";

export default {
  searchPlayer({ commit }, payload) {
    commit("setPlayersSearchLoading", true);
    graphqlClient
      .query({
        query: gql`
          query searchPlayer($searchQuery: String!) {
            searchPlayer(searchQuery: $searchQuery) {
              name
              age
              marketValue
              marketRawValue
              club
              imageUrl
            }
          }
        `,
        variables: {
          searchQuery: payload.searchQuery,
        },
      })
      .then(({ data }) => {
        commit("setPlayers", data.searchPlayer ? data.searchPlayer : []);
        commit("setPlayersSearchLoading", false);
        return;
      })
      .catch((error) => {});
  },

  loadGameResult({ commit }, payload) {
    commit("setGameResult", "");
    graphqlClient
      .query({
        query: gql`
          query getGameResult(
            $homeTeamStrength: Float!
            $guestTeamStrength: Float!
          ) {
            getGameResult(
              homeTeamStrength: $homeTeamStrength
              guestTeamStrength: $guestTeamStrength
            )
          }
        `,
        variables: {
          homeTeamStrength: payload.homeTeamStrength,
          guestTeamStrength: payload.guestTeamStrength,
        },
      })
      .then(({ data }) => {
        commit("setGameResult", data.getGameResult);
        return;
      })
      .catch((error) => {});
  },

  loadInjury({ commit }, payload) {
    commit("setInjury", "");
    graphqlClient
      .query({
        query: gql`
          query getInjury($type: String!) {
            getInjury(type: $type)
          }
        `,
        variables: {
          type: payload.type,
        },
      })
      .then(({ data }) => {
        commit("setInjury", data.getInjury);
        return;
      })
      .catch((error) => {});
  },
};
