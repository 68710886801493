<template>
  <v-app class="app">
    <v-card class="mx-auto overflow-hidden" height="100%" width="100%">
      <v-app-bar color="deep-purple" dark>
        <v-app-bar-nav-icon @click="isDrawerShown = true"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-app-bar>
      <v-navigation-drawer v-model="isDrawerShown" absolute temporary>
        <v-list nav dense>
          <v-list-item-group
            v-model="selectedEntryIndex"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item
              @click="onDrawerListItemPressed"
              v-for="entry in getDrawerNavEntries"
              :key="entry.title"
            >
              <v-list-item-icon>
                <v-icon>{{ entry.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ entry.title }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-card>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    title: null,
    isDrawerShown: false,
    selectedEntryIndex: 0,
  }),
  computed: {
    ...mapGetters("user", ["getDrawerNavEntries"]),
  },
  methods: {
    onDrawerListItemPressed() {
      this.isDrawerShown = false;
    },
  },
  watch: {
    selectedEntryIndex(value, oldValue) {
      if (this.selectedEntryIndex >= this.getDrawerNavEntries.length) {
        return;
      }
      this.title = this.getDrawerNavEntries[this.selectedEntryIndex].title;
      if (oldValue !== this.selectedEntryIndex) {
        this.$router.push(
          this.getDrawerNavEntries[this.selectedEntryIndex].routeTo
        );
      }
    },
  },
  beforeMount() {
    this.title = this.getDrawerNavEntries[0].title;
  },
};
</script>

<style>
.app {
  height: 100vh;
}
.v-application--wrap {
  height: inherit;
}
</style>
