// src/utils/graphql.js
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  },
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

export default new ApolloClient({
  // Provide the URL to the API server.
  link: new HttpLink({
    uri: API_URL
  }),
  // Using a cache for blazingly
  // fast subsequent queries.
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions
});
