import Vue from "vue";
import Router from "vue-router";
import TransferValue from "./../components/tools/transfer/TransferValue.vue";
import ResultValue from "./../components/tools/result/ResultValue.vue";
import InjuryValue from "./../components/tools/injury/InjuryValue.vue";

Vue.use(Router);

const oRouter = new Router({
  routes: [
    {
      path: "/",
      component: TransferValue,
    },
    {
      path: "/result",
      component: ResultValue,
    },
    {
      path: "/injuring",
      component: InjuryValue,
    },
  ],
  mode: "history",
});

export default oRouter;
