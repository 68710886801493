<template>
  <div>
    <v-btn-toggle
      v-model="injuryType"
      mandatory="true"
      tile
      color="deep-purple accent-3"
      group
      class="justify-center"
      style="width: 100%"
    >
      <v-btn value="0">Leicht</v-btn>
      <v-btn value="1">Schwer</v-btn>
    </v-btn-toggle>
    <v-dialog v-model="showDialog" max-width="100%">
      <v-card>
        <v-card-title class="text-h5"> Ergebnis </v-card-title>
        <v-card-text>
          {{ getInjury }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <v-fab-transition>
        <v-btn
          class="calc-btn"
          dark
          absolute
          bottom
          right
          fab
          @click.stop="onLoadInjury"
        >
          <v-icon>mdi-calculator</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      injuryType: "0",
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getInjury"]),
  },
  methods: {
    ...mapActions("user", ["loadInjury"]),
    onLoadInjury() {
      this.loadInjury({
        type: this.injuryType,
      });
      this.showDialog = true;
    },
  },
};
</script>

<style scoped>
.calc-btn {
  right: 10px !important;
  bottom: 10px !important;
}
</style>

