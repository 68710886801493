export default {
  getPlayers(state) {
    return state.players;
  },
  getDrawerNavEntries(state) {
    return state.drawerNavEntries;
  },
  getPlayersSearchLoading(state) {
    return state.playersSearchLoading;
  },
  getGameResult(state) {
    return state.gameResult;
  },
  getInjury(state) {
    return state.injury;
  },
};
