export default {
  setPlayers(state, players) {
    state.players = players;
  },
  setPlayersSearchLoading(state, value) {
    state.playersSearchLoading = value;
  },
  setGameResult(state, value) {
    state.gameResult = value;
  },
  setInjury(state, value) {
    state.injury = value;
  },
};
