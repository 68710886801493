export default {
  players: [],
  playersSearchLoading: false,
  gameResult: "",
  injury: "",
  drawerNavEntries: [
    {
      title: "Transferwert",
      icon: "mdi-currency-eur",
      routeTo: "/",
    },
    {
      title: "Verletzung",
      icon: "mdi-hospital",
      routeTo: "/injuring",
    },
    {
      title: "Ergebnis",
      icon: "mdi-scoreboard",
      routeTo: "/result",
    },
    {
      title: "Vertrag",
      icon: "mdi-handshake",
      routeTo: "/contract",
    },
    {
      title: "Rote Karte",
      icon: "mdi-cards-outline",
      routeTo: "/red_card",
    },
  ],
};
