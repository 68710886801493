<template>
  <div>
    <div class="font-weight-medium pl-3 pt-2">Heim</div>
    <v-rating
      v-model="homeTeamRating"
      background-color="purple lighten-3"
      color="yellow darken-3"
      half-increments
      large
    ></v-rating>
    <div class="font-weight-medium pl-3 pt-2">Auswärts</div>
    <v-rating
      v-model="guestTeamRating"
      background-color="purple lighten-3"
      color="yellow darken-3"
      half-increments
      large
    ></v-rating>
    <v-dialog v-model="showDialog" max-width="100%">
      <v-card>
        <v-card-title class="text-h5"> Ergebnis </v-card-title>
        <v-card-text>
          {{ getGameResult }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showDialog = false">
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <v-fab-transition>
        <v-btn
          class="calc-btn"
          v-show="isReadyToCalculate"
          dark
          absolute
          bottom
          right
          fab
          @click.stop="onCalculateResult"
        >
          <v-icon>mdi-calculator</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      homeTeamRating: 0,
      guestTeamRating: 0,
      calculatedResult: "",
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getGameResult"]),
    isReadyToCalculate() {
      return this.homeTeamRating > 0 && this.guestTeamRating > 0;
    },
  },
  methods: {
    ...mapActions("user", ["loadGameResult"]),
    onCalculateResult() {
      this.loadGameResult({
        homeTeamStrength: this.homeTeamRating,
        guestTeamStrength: this.guestTeamRating,
      });
      this.showDialog = true;
    },
  },
};
</script>

<style scoped>
.calc-btn {
  right: 10px !important;
  bottom: 10px !important;
}
</style>

